@import "~src/app/scss/partials/_breakpoints";

.mat-card-title {
  margin-bottom: 10px !important;
  font-size: 1.3em;
  color: var(--spark-primary-400);
}

.mat-card {
  padding: 1em 2em !important;

  table {
    td {
      padding: 8px 0px;
      font-size: 1.1em;
    }
  }
}

.mat-card .mat-divider-horizontal {
  position: relative !important;
}

.mat-drawer {
  border-right: none !important;

  .dark & {
    box-shadow: none !important;
  }
}

.mat-drawer-content {
  overflow: hidden !important;

  @include media-breakpoint("lt-sm") {
    overflow: auto !important;
  }
}

button[disabled][disabled] {
  background: #eeeeee !important;

  span {
    //color: var(--disabled-color);
  }

  .dark & {
    background: #464646 !important;
  }
}

.mat-card {
  .mat-progress-bar {
    top: 0;
    left: 0;
  }
}

table {
  width: 100%;

  th.mat-header-cell {
    text-align: left;
    font-size: 14px;
    color: #ffffff !important;
  }

  th.mat-sort-header-sorted,
  .mat-sort-header-arrow {
    color: white !important;
  }
}

.mat-icon {
  vertical-align: middle;
}

.mat-icon-20 {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.mat-icon-22 {
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.mat-dialog-container {
  height: auto !important;
  overflow: auto !important;
  margin-top: 18px;
  margin-bottom: 18px;

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: inherit !important;
  }
}

.mat-toolbar-single-row {
  padding-right: 0 !important;
}

.mat-form-field {
  width: 100%;
  min-width: 0;
  font-size: 0.96rem;

  .mat-form-field-fill & {
    .mat-form-field-infix {
      padding: 0.5em 0 4px 0 !important;
    }
  }
}

.no-found-msg {
  padding: 10px;
  color: grey;
  font-size: 15px;
}

.mat-tab-label {
  border-radius: 34px;
}

.mat-tab-label-active {
  opacity: 1 !important;
  @apply bg-primary;
  color: white !important;
}

.mat-tab-header {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-slide-toggle-bar {
  width: 33px !important;
  height: 13px !important;

  .mat-slide-toggle-thumb {
    height: 18px;
    width: 18px;
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* ----------------------------------------------------------------------------------------------------- */
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-accent-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-primary-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-warn-500-rgb), 0.54) !important;
}

.mat-fab {
  position: fixed !important;
  bottom: -5px;
  right: 0;
  margin: 25px !important;
  //background-color: $primary-color;
}

.mat-flat-button {
  min-width: 64px;
  height: 48px;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.filter-form-field {
  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-infix {
    padding: 0.8em 0 1em 0 !important;
    border-top: 0.7em solid transparent !important;
  }
}

.mat-form-field-flex {
  background-color: transparent !important;
}

.mat-form-field-subscript-wrapper {
  z-index: 99;
}

.mat-form-field-appearance-fill {
  .mat-select {
    display: flex;
    align-items: center;

    &:focus {
      .mat-select-trigger {
        .mat-select-value {
          // @apply text-primary #{'!important'};
        }

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            border-top-color: var(--fuse-primary) !important;
          }
        }
      }
    }

    .mat-select-trigger {
      display: flex;
      align-items: center;
      width: 100%;

      .mat-select-value {
        display: flex;
        max-width: none;

        mat-select-trigger {
          .mat-icon {
            margin: 0 !important;
          }
        }
      }

      .mat-select-arrow-wrapper {
        display: flex;
        align-items: center;
        transform: none;
        margin-left: 4px;

        .mat-select-arrow {
          min-height: 0;
          //@apply text-gray-500 dark:text-gray-400 #{'!important'};
        }
      }
    }
  }
}

.mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: 0;
    border: 0;
    position: static;
    display: flex;
    align-items: center;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-flex {
      border-width: 1px;
      border-color: rgb(50, 143, 255) !important;
      padding: 0 16px;
      align-items: stretch;
      min-height: 48px;
      border-radius: 5px;
      @apply shadow-sm bg-white border-gray-300 dark:bg-opacity-5 dark:border-gray-500 #{'!important'};

      .mat-form-field-suffix,
      .mat-form-field-prefix {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        .mat-icon {
          @apply text-gray-400;
        }
      }
    }
  }
}

[hidden] {
  display: none !important;
}

.mat-paginator {
  .mat-paginator-container {
    padding: 8px 16px;
    justify-content: space-between;

    button[disabled][disabled] {
      background: none !important;
    }

    .mat-paginator-page-size {
      align-items: center;
      min-height: 40px;
      margin: 8px;

      .mat-paginator-page-size-select {
        margin: 0;

        .mat-form-field-wrapper {
          margin-bottom: 0;

          .mat-form-field-flex {
            min-height: 32px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.mat-rounded {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border-radius: 24px !important;
    }
  }
}

.mat-dense {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      min-height: 44px;

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        .mat-icon-button {
          width: 32px;
          min-width: 32px;
          height: 32px;
          min-height: 32px;
        }
      }

      .mat-form-field-prefix {
        >.mat-icon-button {
          margin-left: -6px;
          margin-right: 12px;
        }
      }

      .mat-form-field-suffix {
        >.mat-icon-button {
          margin-left: 12px;
          margin-right: -6px;
        }
      }

      .mat-form-field-infix {
        .mat-input-element {
          padding: 12px 0;
        }
      }
    }
  }
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: auto !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.9em 0 0.9em 0 !important;
  border-top: 0.65em solid transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 1em !important;
}

.mat-error {
  font-size: 90% !important;
}

.mat-form-field {
  font-size: 0.9rem !important;
}