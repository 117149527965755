@use "@angular/material" as mat;
@use "sass:map";
@import "~src/app/scss/theme";

/* Include the core Angular Material styles */
@include mat.core();

/* Generate Primary, Accent and Warn palettes */
$palettes: (
);

@each $name in (primary, accent, warn) {
  $palettes: map.merge($palettes,
    (#{$name}: (50: var(--spark-#{$name}-50),
        100: var(--spark-#{$name}-100),
        200: var(--spark-#{$name}-200),
        300: var(--spark-#{$name}-300),
        400: var(--spark-#{$name}-400),
        500: var(--spark-#{$name}-500),
        600: var(--spark-#{$name}-600),
        700: var(--spark-#{$name}-700),
        800: var(--spark-#{$name}-800),
        900: var(--spark-#{$name}-900),
        contrast: (50: var(--spark-on-#{$name}-50),
          100: var(--spark-on-#{$name}-100),
          200: var(--spark-on-#{$name}-200),
          300: var(--spark-on-#{$name}-300),
          400: var(--spark-on-#{$name}-400),
          500: var(--spark-on-#{$name}-500),
          600: var(--spark-on-#{$name}-600),
          700: var(--spark-on-#{$name}-700),
          800: var(--spark-on-#{$name}-800),
          900: var(--spark-on-#{$name}-900),
        ),
        default: var(--spark-#{$name}),
        lighter: var(--spark-#{$name}-100),
        darker: var(--spark-#{$name}-700),
        text: var(--spark-#{$name}),
        default-contrast: var(--spark-on-#{$name}),
        lighter-contrast: var(--spark-on-#{$name}-100),
        darker-contrast: var(--spark-on-#{$name}-700),
      )));
}

$mat-typography: mat.define-typography-config($font-family: 'Lexend, sans-serif', );

/* Generate Angular Material themes. Since we are using CSS Custom Properties,
    we don't have to generate a separate Angular Material theme for each color
    set. We can just create one light and one dark theme and then switch the
    CSS Custom Properties to dynamically switch the colors. */
body.light,
body .light {
  $base-light-theme: mat.define-light-theme((color: (primary: map.get($palettes, primary),
        accent: map.get($palettes, accent),
        warn: map.get($palettes, warn),
      ),
      typography: $mat-typography ));
  @include spark-theme($base-light-theme);
  @include mat.all-component-themes($base-light-theme);
}

body.dark,
body .dark {
  $base-dark-theme: mat.define-dark-theme((color: (primary: map.get($palettes, primary),
        accent: map.get($palettes, accent),
        warn: map.get($palettes, warn),
      ),
      typography: $mat-typography ));
  @include spark-theme($base-dark-theme);
  @include mat.all-component-themes($base-dark-theme);

  #spark-splash-screen {
    background-color: #3c3c3c;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #3c3c3c inset;
    -webkit-text-fill-color: #fff;
  }

  .ngx-toastr {
    box-shadow: 0 0 12px #1d1d1d !important;
  }
}