@import "~@angular/cdk/overlay-prebuilt.css";
.hidden-file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  font-size: 0;
  cursor: pointer;
}

.control-box {
  display: flex;
  width: 100%;
}
@media (max-width: 600px) {
  .control-box {
    flex-direction: column;
  }
}

.mat-error {
  font-size: 80%;
}

.right-align-controls {
  display: flex;
  justify-content: flex-end;
}

.success-button:enabled {
  background-color: green !important;
  color: white !important;
}

.mat-radio-button {
  margin: 0 12px;
}

.validation-list {
  width: 270px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin: 0;
  z-index: 99;
}
.validation-list .verified {
  color: black;
}
.validation-list .verified .mat-icon {
  color: #00c200;
}
.validation-list .unverified {
  color: rgba(var(--spark-text-secondary-rgb), 0.8);
}
.validation-list .unverified .mat-icon {
  color: rgba(var(--spark-text-secondary-rgb), 0.8);
}

.external-validation-list {
  background: none !important;
  border: none;
  width: auto;
  padding: 0 0 10px 0 !important;
}
.external-validation-list .verified {
  color: inherit;
}
.external-validation-list > li {
  font-size: 0.9rem !important;
  padding: 3px 0 !important;
}
.external-validation-list > li .mat-icon {
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
  margin-right: 4px !important;
}
.external-validation-list > li:last-child {
  @apply col-span-2 sm:col-span-1;
}

.validation-list > li {
  list-style-type: none;
  padding: 8px 0;
}
.validation-list > li .mat-icon {
  font-size: 23px;
  width: 23px;
  height: 21px;
  vertical-align: bottom;
  margin-right: 5px;
}

.validation-list > li:last-child {
  border-bottom: none;
}

.upload-box {
  margin-bottom: 10px;
  position: relative;
  display: flex;
}
.upload-box .disable-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: not-allowed;
}
@media screen and (max-width: 599px) {
  .upload-box .empty-drag-section {
    display: none !important;
  }
}
.upload-box .drag-section {
  border: 1px dotted #949494;
  height: 40px;
  flex-grow: 1;
  display: flex;
  vertical-align: middle;
  position: relative;
  border-radius: 3px 0px 0px 3px;
}
@media screen and (max-width: 599px) {
  .upload-box .drag-section {
    width: 98%;
    margin-bottom: 10px;
  }
  .upload-box .drag-section .drag-message {
    display: none;
  }
}
.upload-box .drag-section span,
.upload-box .drag-section .file-data {
  position: absolute;
  margin: auto;
  width: max-content;
  left: 0;
  right: 0;
  color: grey;
  font-size: 14px;
  height: max-content;
  top: 0;
  bottom: 0;
}
.upload-box .drag-section .file-data {
  width: 100%;
  padding: 11px;
}
.upload-box .drag-section .file-data .mat-icon {
  color: #a1a1a1;
  width: 19px;
  height: 19px;
  font-size: 19px;
  cursor: pointer;
}
.upload-box .drag-section .file-data .file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upload-box .select-file {
  background: green;
  display: flex;
  color: #fff;
  align-items: center;
  border-radius: 0px 3px 3px 0px;
}
.upload-box .select-file input {
  width: 100%;
}
.upload-box .select-file label {
  padding: 8px;
  cursor: pointer;
}
@media screen and (max-width: 599px) {
  .upload-box .select-file {
    width: 100%;
    margin-left: 0px;
  }
  .upload-box .select-file input {
    display: block !important;
  }
}
.upload-box .select-file input::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-box .select-file input::before {
  content: "Browse";
  display: inline-block;
  padding: 11px 15px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: white;
  font-size: 11pt;
}

.dark .otp-input {
  background-color: #303030;
}

.mat-card-title {
  margin-bottom: 10px !important;
  font-size: 1.3em;
  color: var(--spark-primary-400);
}

.mat-card {
  padding: 1em 2em !important;
}
.mat-card table td {
  padding: 8px 0px;
  font-size: 1.1em;
}

.mat-card .mat-divider-horizontal {
  position: relative !important;
}

.mat-drawer {
  border-right: none !important;
}
.dark .mat-drawer {
  box-shadow: none !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}
@media screen and (max-width: 599px) {
  .mat-drawer-content {
    overflow: auto !important;
  }
}

button[disabled][disabled] {
  background: #eeeeee !important;
}
.dark button[disabled][disabled] {
  background: #464646 !important;
}

.mat-card .mat-progress-bar {
  top: 0;
  left: 0;
}

table {
  width: 100%;
}
table th.mat-header-cell {
  text-align: left;
  font-size: 14px;
  color: #ffffff !important;
}
table th.mat-sort-header-sorted,
table .mat-sort-header-arrow {
  color: white !important;
}

.mat-icon {
  vertical-align: middle;
}

.mat-icon-20 {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.mat-icon-22 {
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.mat-dialog-container {
  height: auto !important;
  overflow: auto !important;
  margin-top: 18px;
  margin-bottom: 18px;
}
.mat-dialog-container .mat-stepper-horizontal,
.mat-dialog-container .mat-stepper-vertical {
  background-color: inherit !important;
}

.mat-toolbar-single-row {
  padding-right: 0 !important;
}

.mat-form-field {
  width: 100%;
  min-width: 0;
  font-size: 0.96rem;
}
.mat-form-field-fill .mat-form-field .mat-form-field-infix {
  padding: 0.5em 0 4px 0 !important;
}

.no-found-msg {
  padding: 10px;
  color: grey;
  font-size: 15px;
}

.mat-tab-label {
  border-radius: 34px;
}

.mat-tab-label-active {
  opacity: 1 !important;
  @apply bg-primary;
  color: white !important;
}

.mat-tab-header {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-slide-toggle-bar {
  width: 33px !important;
  height: 13px !important;
}
.mat-slide-toggle-bar .mat-slide-toggle-thumb {
  height: 18px;
  width: 18px;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* ----------------------------------------------------------------------------------------------------- */
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-accent-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-primary-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--spark-warn-500-rgb), 0.54) !important;
}

.mat-fab {
  position: fixed !important;
  bottom: -5px;
  right: 0;
  margin: 25px !important;
}

.mat-flat-button {
  min-width: 64px;
  height: 48px;
}
.mat-flat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.filter-form-field .mat-form-field-wrapper {
  padding: 0;
}
.filter-form-field .mat-form-field-infix {
  padding: 0.8em 0 1em 0 !important;
  border-top: 0.7em solid transparent !important;
}

.mat-form-field-flex {
  background-color: transparent !important;
}

.mat-form-field-subscript-wrapper {
  z-index: 99;
}

.mat-form-field-appearance-fill .mat-select {
  display: flex;
  align-items: center;
}
.mat-form-field-appearance-fill .mat-select:focus .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  border-top-color: var(--fuse-primary) !important;
}
.mat-form-field-appearance-fill .mat-select .mat-select-trigger {
  display: flex;
  align-items: center;
  width: 100%;
}
.mat-form-field-appearance-fill .mat-select .mat-select-trigger .mat-select-value {
  display: flex;
  max-width: none;
}
.mat-form-field-appearance-fill .mat-select .mat-select-trigger .mat-select-value mat-select-trigger .mat-icon {
  margin: 0 !important;
}
.mat-form-field-appearance-fill .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
  display: flex;
  align-items: center;
  transform: none;
  margin-left: 4px;
}
.mat-form-field-appearance-fill .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  min-height: 0;
}

.mat-form-field.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  border: 0;
  position: static;
  display: flex;
  align-items: center;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  border-width: 1px;
  border-color: #328fff !important;
  padding: 0 16px;
  align-items: stretch;
  min-height: 48px;
  border-radius: 5px;
  @apply shadow-sm bg-white border-gray-300 dark:bg-opacity-5 dark:border-gray-500 !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon {
  @apply text-gray-400;
}

[hidden] {
  display: none !important;
}

.mat-paginator .mat-paginator-container {
  padding: 8px 16px;
  justify-content: space-between;
}
.mat-paginator .mat-paginator-container button[disabled][disabled] {
  background: none !important;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size {
  align-items: center;
  min-height: 40px;
  margin: 8px;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select {
  margin: 0;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-form-field-wrapper {
  margin-bottom: 0;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 32px;
  padding: 0 10px;
}

.mat-rounded .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 24px !important;
}

.mat-dense .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 44px;
}
.mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button,
.mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}
.mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin-left: -6px;
  margin-right: 12px;
}
.mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin-left: 12px;
  margin-right: -6px;
}
.mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 12px 0;
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: auto !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.9em 0 0.9em 0 !important;
  border-top: 0.65em solid transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 1em !important;
}

.mat-error {
  font-size: 90% !important;
}

.mat-form-field {
  font-size: 0.9rem !important;
}

@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Thin.ttf");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lexend";
  src: url("~src/assets/fonts/Lexend-Black.ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all;
}
.swal2-popup.swal2-toast > * {
  grid-column: 2;
}
.swal2-popup.swal2-toast .swal2-title {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}
.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: 0.5em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: 0.8em;
  height: 0.8em;
  margin: 0;
  font-size: 2em;
}
.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}
.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.5em 0 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: 0.5em;
  padding: 0 0.5em;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.25em 0.5em;
  padding: 0.4em 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}
.swal2-container.swal2-top-start, .swal2-container.swal2-center-start, .swal2-container.swal2-bottom-start {
  grid-template-columns: minmax(0, 1fr) auto auto;
}
.swal2-container.swal2-top, .swal2-container.swal2-center, .swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}
.swal2-container.swal2-top-end, .swal2-container.swal2-center-end, .swal2-container.swal2-bottom-end {
  grid-template-columns: auto auto minmax(0, 1fr);
}
.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start;
}
.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center;
}
.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end;
}
.swal2-container.swal2-center-start > .swal2-popup, .swal2-container.swal2-center-left > .swal2-popup {
  grid-row: 2;
  align-self: center;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
}
.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end;
}
.swal2-container.swal2-bottom-start > .swal2-popup, .swal2-container.swal2-bottom-left > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end;
}
.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end;
}
.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end;
}
.swal2-container.swal2-grow-row > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-column: 1/4;
  width: 100%;
}
.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent;
}

.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5);
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5);
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5);
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled:focus {
  outline: none;
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: inherit;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em;
}

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}
.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #f27474;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em 2em 3px;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color 0.1s, box-shadow 0.1s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #ccc;
}

.swal2-range {
  margin: 1em 2em 3px;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 1.125em;
}
.swal2-radio input,
.swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 0.4em;
}

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0;
}

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-warning.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.5s;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-info.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.8s;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-question.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-question-mark 0.8s;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}

[class^=swal2] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show 0.3s;
}

.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none;
}
body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all;
}
body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

::-ms-reveal {
  display: none;
}

body.spark-splash-screen-hidden #spark-splash-screen {
  visibility: hidden;
  opacity: 0;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-cancel {
  color: black !important;
}

* {
  margin: 0;
  padding: 0;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  font-size: 100%;
}

body {
  margin: 0;
  font-family: Lexend, sans-serif !important;
}

a {
  cursor: pointer;
}

.vertical-text-line:before,
.vertical-text-line:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 45%;
  height: 1px;
  content: " ";
  background-color: var(--spark-border);
}

.vertical-text-line:before {
  margin-left: -47%;
  text-align: right;
}

.vertical-text-line:after {
  margin-left: 2%;
}

.table-message {
  padding: 13px 12px;
}
.table-message .mat-icon {
  vertical-align: middle;
}
.table-message span {
  vertical-align: middle;
  margin-left: 7px;
}

.content {
  padding: 35px;
  position: relative;
}

.loading-content {
  height: 100%;
  overflow: hidden;
}

.welcome-toast {
  background-color: #009add !important;
  top: 57px;
  width: max-content !important;
}
.welcome-toast .toast-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 4px;
}

.ngx-toastr-dark {
  box-shadow: 0 0 12px #1d1d1d !important;
}

.page-title {
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 18px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}

.page-header {
  margin-bottom: 10px;
}

.add-round-button {
  padding: 4px 28px !important;
  border-radius: 29px !important;
  font-size: 0.9rem;
}

.process-toaster {
  background-color: #393939 !important;
  width: max-content !important;
  padding-left: 15px !important;
}

.footer-text {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  line-height: 1.8;
}
.footer-text a {
  color: inherit !important;
}
@media screen and (max-width: 599px) {
  .footer-text {
    position: relative;
  }
}

.logo {
  width: 120px;
  display: block;
  margin: auto;
  margin-bottom: 9px;
}

::-webkit-scrollbar {
  width: 8px;
}
@media screen and (max-width: 599px) {
  ::-webkit-scrollbar {
    width: 6px;
  }
}

div:not(.router-div)::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #e4e4e4;
}
.dark ::-webkit-scrollbar-track {
  border: 1px solid #525252 !important;
  background: grey !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

div:not(.router-div)::-webkit-scrollbar-thumb {
  background: #dadadad1;
}

.breadcrumb {
  list-style-type: none;
}
.breadcrumb li {
  display: inline-block;
  padding: 8px 5px 14px 0;
}
.breadcrumb li a {
  text-decoration: none;
}
.dark .breadcrumb li a:visited {
  color: #00acff !important;
}
.breadcrumb li a:visited {
  color: #004bbe;
}
.breadcrumb li:not(:first-child):before {
  content: "/";
  padding-right: 5px;
  color: #8f8f8f;
}

.trash {
  position: relative;
  cursor: pointer;
  width: 11px;
  height: 12px;
  display: inline-block;
  margin-top: 5px;
  background: #949494;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  vertical-align: middle;
}
.trash i:after {
  content: "";
  /* background: black; */
  position: absolute;
  margin: auto;
  left: 0;
  right: -5px;
  bottom: 0;
  top: 0px;
  width: 2px;
  height: 10px;
}
.trash i::before {
  content: "";
  /* background: #000000; */
  position: absolute;
  left: 4px;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 2px;
  height: 10px;
}
.trash span {
  position: absolute;
  width: 16px;
  background: #949494;
  height: 3px;
  display: block;
  top: -4px;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: -10px;
  right: -10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.trash span:before {
  content: "";
  position: absolute;
  width: 9px;
  background: #949494;
  height: 2px;
  display: block;
  top: -2px;
  margin: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: -10px;
  right: -10px;
}

.actions {
  display: inline-block;
  vertical-align: middle;
}
.actions .launch-icon {
  @apply icon-size-5 mr-4 text-gray-400;
  cursor: pointer;
}
.actions .launch-icon:hover {
  color: #0b803f !important;
}
.actions .launch-icon:focus {
  outline: 0;
}

.trash:hover {
  background-color: #d50000 !important;
}
.trash:hover span {
  transition: transform 250ms;
  transform: rotate(-29deg);
  background-color: #d50000 !important;
}
.trash:hover span:before {
  background-color: #d50000 !important;
}