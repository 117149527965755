@import "~src/app/scss/partials/_breakpoints";

.hidden-file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  font-size: 0;
  cursor: pointer;
}

.control-box {
  display: flex;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.mat-error {
  font-size: 80%;
}

.right-align-controls {
  display: flex;
  justify-content: flex-end;
}

.success-button:enabled {
  background-color: green !important;
  color: white !important;
}

.mat-radio-button {
  margin: 0 12px;
}

.validation-list {
  width: 270px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin: 0;
  z-index: 99;

  .verified {
    color: black;

    .mat-icon {
      color: #00c200;
    }
  }

  .unverified {
    color: rgba(var(--spark-text-secondary-rgb), 0.8);

    .mat-icon {
      color: rgba(var(--spark-text-secondary-rgb), 0.8);
    }
  }
}

.external-validation-list {
  background: none !important;
  border: none;
  width: auto;
  padding: 0 0 10px 0 !important;

  .verified {
    color: inherit;
  }

  &>li {
    font-size: 0.9rem !important;
    padding: 3px 0 !important;

    .mat-icon {
      width: 18px !important;
      height: 18px !important;
      font-size: 18px !important;
      margin-right: 4px !important;
    }
  }

  &>li:last-child {
    @apply col-span-2 sm:col-span-1;
  }
}

.validation-list>li {
  list-style-type: none;
  padding: 8px 0;

  .mat-icon {
    font-size: 23px;
    width: 23px;
    height: 21px;
    vertical-align: bottom;
    margin-right: 5px;
  }
}

.validation-list>li:last-child {
  border-bottom: none;
}

.upload-box {
  margin-bottom: 10px;
  position: relative;
  display: flex;

  .disable-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    //background: #ffffff87;
    z-index: 999;
    cursor: not-allowed;
  }

  .empty-drag-section {
    @include media-breakpoint("lt-sm") {
      display: none !important;
    }
  }

  .drag-section {
    border: 1px dotted #949494;
    height: 40px;
    flex-grow: 1;
    display: flex;
    vertical-align: middle;
    position: relative;
    border-radius: 3px 0px 0px 3px;

    @include media-breakpoint("lt-sm") {
      width: 98%;
      margin-bottom: 10px;

      .drag-message {
        display: none;
      }
    }

    span,
    .file-data {
      position: absolute;
      margin: auto;
      width: max-content;
      left: 0;
      right: 0;
      color: grey;
      font-size: 14px;
      height: max-content;
      top: 0;
      bottom: 0;
    }

    .file-data {
      width: 100%;
      padding: 11px;

      .mat-icon {
        color: #a1a1a1;
        width: 19px;
        height: 19px;
        font-size: 19px;
        cursor: pointer;
      }

      .file-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .select-file {
    background: green;
    display: flex;
    color: #fff;
    align-items: center;
    border-radius: 0px 3px 3px 0px;

    input {
      width: 100%;
    }

    label {
      padding: 8px;
      cursor: pointer;
    }

    @include media-breakpoint("lt-sm") {
      width: 100%;
      margin-left: 0px;

      input {
        display: block !important;
      }
    }
  }

  .select-file input::-webkit-file-upload-button {
    visibility: hidden;
  }

  .select-file input::before {
    content: "Browse";
    display: inline-block;
    padding: 11px 15px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: white;
    font-size: 11pt;
  }
}

.dark {
  .otp-input {
    background-color: #303030;
  }
}