@import "~src/app/scss/partials/_form";
@import "~src/app/scss/partials/_material_fixes";
@import "~src/app/scss/partials/_breakpoints";
@import "~src/app/scss/partials/_fonts";
@import "sweetalert2/src/sweetalert2";
@import "~@angular/cdk/overlay-prebuilt.css";

::-ms-reveal {
  display: none;
}

body.spark-splash-screen-hidden #spark-splash-screen {
  visibility: hidden;
  opacity: 0;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-cancel {
  color: black !important;
}

* {
  margin: 0;
  padding: 0;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-size: 100%;
}

body {
  margin: 0;
  font-family: Lexend, sans-serif !important;
}

a {
  cursor: pointer;
}

.vertical-text-line:before,
.vertical-text-line:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 45%;
  height: 1px;
  content: " ";
  background-color: var(--spark-border);
}

.vertical-text-line:before {
  margin-left: -47%;
  text-align: right;
}

.vertical-text-line:after {
  margin-left: 2%;
}

.table-message {
  padding: 13px 12px;

  .mat-icon {
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
    margin-left: 7px;
  }
}

.content {
  padding: 35px;
  position: relative;
}

.loading-content {
  height: 100%;
  overflow: hidden;
}

.welcome-toast {
  background-color: #009add !important;
  top: 57px;
  width: max-content !important;

  .toast-title {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 4px;
  }
}

.ngx-toastr-dark {
  box-shadow: 0 0 12px #1d1d1d !important;
}

.page-title {
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 18px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}

.page-header {
  margin-bottom: 10px;
}

.add-round-button {
  padding: 4px 28px !important;
  border-radius: 29px !important;
  font-size: 0.9rem;
}

.process-toaster {
  background-color: #393939 !important;
  width: max-content !important;
  padding-left: 15px !important;
}

.footer-text {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  line-height: 1.8;

  a {
    color: inherit !important;
  }

  @include media-breakpoint("lt-sm") {
    position: relative;
  }
}

.logo {
  width: 120px;
  display: block;
  margin: auto;
  margin-bottom: 9px;
}

::-webkit-scrollbar {
  width: 8px;

  @include media-breakpoint("lt-sm") {
    width: 6px;
  }
}

div:not(.router-div)::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #e4e4e4;

  .dark & {
    border: 1px solid #525252 !important;
    background: grey !important;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  //background: #0086fbd1;
  border-radius: 10px;
}

div:not(.router-div)::-webkit-scrollbar-thumb {
  background: #dadadad1;
}

.breadcrumb {
  list-style-type: none;

  li {
    display: inline-block;
    padding: 8px 5px 14px 0;

    a {
      text-decoration: none;
    }

    .dark & {
      a:visited {
        color: #00acff !important;
      }
    }

    a:visited {
      color: #004bbe;
    }
  }

  li:not(:first-child):before {
    content: "/";
    padding-right: 5px;
    color: #8f8f8f;
  }
}

.trash {
  position: relative;
  cursor: pointer;
  width: 11px;
  height: 12px;
  display: inline-block;
  margin-top: 5px;
  background: #949494;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  vertical-align: middle;

  i {}

  i:after {
    content: "";
    /* background: black; */
    position: absolute;
    margin: auto;
    left: 0;
    right: -5px;
    bottom: 0;
    top: 0px;
    width: 2px;
    height: 10px;
  }

  i::before {
    content: "";
    /* background: #000000; */
    position: absolute;
    left: 4px;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 2px;
    height: 10px;
  }

  span {
    position: absolute;
    width: 16px;
    background: #949494;
    height: 3px;
    display: block;
    top: -4px;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    left: -10px;
    right: -10px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform-origin: 19% 100%;
  }

  span:before {
    content: "";
    position: absolute;
    width: 9px;
    background: #949494;
    height: 2px;
    display: block;
    top: -2px;
    margin: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    left: -10px;
    right: -10px;
  }
}

.actions {
  display: inline-block;
  vertical-align: middle;

  .launch-icon {
    @apply icon-size-5 mr-4 text-gray-400;
    cursor: pointer;
  }

  .launch-icon:hover {
    color: #0b803f !important;
  }

  .launch-icon:focus {
    outline: 0;
  }
}

.trash:hover {
  background-color: #d50000 !important;

  span {
    transition: transform 250ms;
    transform: rotate(-29deg);
    background-color: #d50000 !important;
  }

  span:before {
    background-color: #d50000 !important;
  }
}