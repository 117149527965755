@use "sass:map";
@use "@angular/material" as mat;

@mixin spark-theme($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary: map.get($color-config, "primary");
    $accent: map.get($color-config, "accent");
    $warn: map.get($color-config, "primary");
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $secondary-color: map-get($theme, disabled-text);

    a {
        color: mat.get-color-from-palette($primary);
    }
    .dark {
        a {
            color: mat.get-color-from-palette($primary, 400);
        }
    }

    .mat-dialog-container {
        background-color: mat.get-color-from-palette($background, background) !important;
    }
    .disable-overlay {
        background-color: mat.get-color-from-palette($foreground, disabled-button);
        // @apply text-red-500 #{'!important'};
    }

    ::-webkit-scrollbar-thumb {
        background: mat.get-color-from-palette($accent);
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: mat.get-color-from-palette($accent, 500);
    }
    .highlight-box {
        background-color: mat.get-color-from-palette($primary, 400);
    }
    app-navigation-basic,
    app-navigation-collapsable,
    .submenu-overlay {
        .active {
            background-color: mat.get-color-from-palette($primary, 600) !important;
        }

        .nav-item {
            color: mat.get-color-from-palette($primary, 800);
        }
    }
    .submenu {
        .nav-item:hover {
            color: mat.get-color-from-palette($primary, 800) !important;
        }
    }

    .company-profile {
        h3 {
            color: mat.get-color-from-palette($primary, 800);
        }
    }
    tr.mat-header-row {
        background-color: mat.get-color-from-palette($primary, 600);
    }
    .secondary-text {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }
    .disabled-text {
        color: mat.get-color-from-palette($foreground, disabled-text);
    }
}
